import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { Error404Component } from './modules/auth/error404/error404.component';

const routes: Routes = [
  { 
    path: '', 
    canActivate: [authGuard],
    loadChildren: () => import('../app/modules/layout/layout.module').then(m => m.LayoutModule)
   },
   {
    path: '',
    loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule)
   },
   {
    path: '**',
    component: Error404Component
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
