import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '../services/graphql-services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class jwtInterceptor implements HttpInterceptor  {
  constructor(private _auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = JSON.parse(localStorage.getItem('user')!);
    if(user && user?.token) {
      req = req.clone({
        setHeaders: {
          "Authorization": `Bearer ${user?.token}`
        }
      });
    }
    return next.handle(req);
  }
};
