import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {

  const token = localStorage.getItem('user');
  const _route = inject(Router);
  if(token) {
    return true;
  }
  _route.navigate(['/login']);
  return false;
};
