import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  ngOnInit() {
    const body = document.querySelector('body') as HTMLElement;
    body.classList.remove('mat-typography');
  }
}
